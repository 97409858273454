<template class="bg-white h-full">
  <div class="bg-white h-full">
    <div class="h-44 bg-gray-800 flex items-center justify-center">
      <h1 class="font-bold text-4xl text-white">Conditions d'utilisations</h1>
    </div>
    <div class="text-center lg:ml-44 lg:mr-44 bg-gray-100 p-4">
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        Bienvenue sur AfriPlume ! Ces Conditions Générales d'Utilisation (CGU)
        régissent l'accès et l'utilisation de la plateforme AfriPlume. En
        accédant à la plateforme, vous acceptez de vous conformer aux présentes
        CGU. Si vous n'acceptez pas ces conditions, vous devez cesser d'utiliser
        la plateforme
      </h1>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        1. Objet des CGU
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Les présentes CGU ont pour objet de définir les conditions et modalités
        selon lesquelles les utilisateurs accèdent et utilisent la plateforme
        AfriPlume. Elles visent également à encadrer les droits et obligations
        des utilisateurs et de l'éditeur de la plateforme.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        2. Accès à la plateforme
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        AfriPlume est accessible à toute personne disposant d'une connexion
        Internet. L'accès aux services et fonctionnalités de la plateforme peut
        être soumis à inscription. AfriPlume se réserve le droit de refuser
        l'accès à un utilisateur en cas de non-respect des CGU.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        2.1. Inscription
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Pour accéder à certains services de la plateforme, les utilisateurs
        peuvent être tenus de créer un compte en fournissant des informations
        personnelles exactes et à jour. L'utilisateur est responsable de la
        confidentialité de ses identifiants et doit informer immédiatement
        AfriPlume en cas de perte ou de vol de ces informations.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        3. Utilisation de la plateforme
      </h1>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        3.1. Contenus proposés
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        AfriPlume est une plateforme dédiée à la lecture et à l'écriture de web
        romans et autres œuvres littéraires. Les utilisateurs peuvent accéder à
        du contenu gratuit ou payant en fonction de l'offre disponible sur la
        plateforme.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        3.2. Contributions des utilisateurs
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Les utilisateurs ont la possibilité de publier des œuvres originales sur
        la plateforme, sous réserve du respect des droits d'auteur et des lois
        en vigueur. Ils s'engagent à ne pas publier de contenu diffamatoire,
        offensant, ou illicite.<br />
        AfriPlume se réserve le droit de modérer ou de supprimer tout contenu
        non conforme.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        3.3. Propriété intellectuelle
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        Les utilisateurs conservent les droits de propriété intellectuelle sur
        les œuvres qu'ils publient. Toutefois, en publiant sur la plateforme,
        ils accordent à AfriPlume une licence non exclusive, gratuite et
        mondiale pour diffuser, afficher et promouvoir les œuvres sur ses
        différents supports.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        4. Conditions financières
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        L'accès à certains contenus ou services peut être payant. Les modalités
        de paiement et les tarifs sont précisés sur la plateforme. AfriPlume se
        réserve le droit de modifier ses tarifs à tout moment, sans préavis.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        5. Protection des données personnelles
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        AfriPlume s'engage à respecter la vie privée de ses utilisateurs et à
        protéger leurs données personnelles conformément à la réglementation en
        vigueur. Les utilisateurs disposent d'un droit d'accès, de modification
        et de suppression de leurs données. Pour plus d’informations, veuillez
        consulter notre Politique de Confidentialité
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        6. Responsabilités
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        AfriPlume met tout en œuvre pour assurer la disponibilité de la
        plateforme, mais ne saurait être tenue responsable en cas d’interruption
        de service, de perte de données ou d’autres dysfonctionnements liés à
        des causes extérieures (pannes, attaques, etc.).<br />
        <br />
        Les utilisateurs sont seuls responsables des contenus qu'ils publient et
        des conséquences de leur diffusion.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        7. Modifications des CGU
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        AfriPlume se réserve le droit de modifier les présentes CGU à tout
        moment. Les utilisateurs seront informés de ces modifications par tout
        moyen approprié (notification sur le site, email, etc.). La poursuite de
        l’utilisation de la plateforme après modification vaut acceptation des
        nouvelles conditions.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        8. Résiliation
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        AfriPlume se réserve le droit de suspendre ou de supprimer un compte
        utilisateur en cas de non-respect des CGU ou de toute activité illicite.
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        9. Droit applicable et juridiction compétente
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        Les présentes CGU sont régies et interprétées conformément aux lois de
        la juridiction où se trouve le siège de AfriPlume. En cas de litige, les
        tribunaux béninois seront seuls compétents.
      </p>
    </div>

    <div class="h-6"></div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
</style>
