<template>
  <div
    class="relative overflow-x-auto shadow-md sm:rounded-lg bg-white p-4 mt-9"
  >
  <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>
    <div class="flex flex-wrap -mx-3 mb-20">
      <div class="w-1/2 xl:w-1/4 px-3">
        <div
          class="w-full bg-white border text-blue-400 rounded-lg lg:flex p-6 mb-6 xl:mb-0"
        >
          <svg
            class="w-16 h-16 fill-current lg:mr-4 ml-4 lg:ml-0"
            viewBox="0 0 20 20"
          >
            <path
              d="M17.35,2.219h-5.934c-0.115,0-0.225,0.045-0.307,0.128l-8.762,8.762c-0.171,0.168-0.171,0.443,0,0.611l5.933,5.934c0.167,0.171,0.443,0.169,0.612,0l8.762-8.763c0.083-0.083,0.128-0.192,0.128-0.307V2.651C17.781,2.414,17.587,2.219,17.35,2.219M16.916,8.405l-8.332,8.332l-5.321-5.321l8.333-8.332h5.32V8.405z M13.891,4.367c-0.957,0-1.729,0.772-1.729,1.729c0,0.957,0.771,1.729,1.729,1.729s1.729-0.772,1.729-1.729C15.619,5.14,14.848,4.367,13.891,4.367 M14.502,6.708c-0.326,0.326-0.896,0.326-1.223,0c-0.338-0.342-0.338-0.882,0-1.224c0.342-0.337,0.881-0.337,1.223,0C14.84,5.826,14.84,6.366,14.502,6.708"
            ></path>
          </svg>

          <div class="text-gray-700">
            <p class="font-bold text-3xl">{{ books.length }}</p>
            <p class="font-bold text-sm lg:whitespace-nowrap">
              Nombre total de livre
            </p>
          </div>
        </div>
      </div>

      <div class="w-1/2 xl:w-1/4 px-3">
        <div
          class="w-full bg-white border text-blue-400 rounded-lg lg:flex p-6 mb-6 xl:mb-0"
        >
          <svg
            class="w-16 h-16 fill-current lg:mr-4 ml-4 lg:ml-0"
            viewBox="0 0 20 20"
          >
            <path
              d="M17.684,7.925l-5.131-0.67L10.329,2.57c-0.131-0.275-0.527-0.275-0.658,0L7.447,7.255l-5.131,0.67C2.014,7.964,1.892,8.333,2.113,8.54l3.76,3.568L4.924,17.21c-0.056,0.297,0.261,0.525,0.533,0.379L10,15.109l4.543,2.479c0.273,0.153,0.587-0.089,0.533-0.379l-0.949-5.103l3.76-3.568C18.108,8.333,17.986,7.964,17.684,7.925 M13.481,11.723c-0.089,0.083-0.129,0.205-0.105,0.324l0.848,4.547l-4.047-2.208c-0.055-0.03-0.116-0.045-0.176-0.045s-0.122,0.015-0.176,0.045l-4.047,2.208l0.847-4.547c0.023-0.119-0.016-0.241-0.105-0.324L3.162,8.54L7.74,7.941c0.124-0.016,0.229-0.093,0.282-0.203L10,3.568l1.978,4.17c0.053,0.11,0.158,0.187,0.282,0.203l4.578,0.598L13.481,11.723z"
            ></path>
          </svg>

          <div class="text-gray-700">
            <p class="font-semibold text-3xl">0</p>
            <p class="font-bold text-sm lg:whitespace-nowrap">Note moyenne</p>
          </div>
        </div>
      </div>

      <div class="w-1/2 xl:w-1/4 px-3">
        <div
          class="w-full bg-white border text-blue-400 rounded-lg lg:flex p-6"
        >
          <svg
            class="w-16 h-16 fill-current lg:mr-4 ml-4 lg:ml-0"
            viewBox="0 0 20 20"
          >
            <path
              d="M14.999,8.543c0,0.229-0.188,0.417-0.416,0.417H5.417C5.187,8.959,5,8.772,5,8.543s0.188-0.417,0.417-0.417h9.167C14.812,8.126,14.999,8.314,14.999,8.543 M12.037,10.213H5.417C5.187,10.213,5,10.4,5,10.63c0,0.229,0.188,0.416,0.417,0.416h6.621c0.229,0,0.416-0.188,0.416-0.416C12.453,10.4,12.266,10.213,12.037,10.213 M14.583,6.046H5.417C5.187,6.046,5,6.233,5,6.463c0,0.229,0.188,0.417,0.417,0.417h9.167c0.229,0,0.416-0.188,0.416-0.417C14.999,6.233,14.812,6.046,14.583,6.046 M17.916,3.542v10c0,0.229-0.188,0.417-0.417,0.417H9.373l-2.829,2.796c-0.117,0.116-0.71,0.297-0.71-0.296v-2.5H2.5c-0.229,0-0.417-0.188-0.417-0.417v-10c0-0.229,0.188-0.417,0.417-0.417h15C17.729,3.126,17.916,3.313,17.916,3.542 M17.083,3.959H2.917v9.167H6.25c0.229,0,0.417,0.187,0.417,0.416v1.919l2.242-2.215c0.079-0.077,0.184-0.12,0.294-0.12h7.881V3.959z"
            ></path>
          </svg>

          <div class="text-gray-700">
            <p class="font-semibold text-3xl">{{ abonner.length }}</p>
            <p class="font-bold text-sm lg:whitespace-nowrap">
              Nombre total d’abonnés
            </p>
          </div>
        </div>
      </div>

      <div class="w-1/2 xl:w-1/4 px-3">
        <div
          class="w-full bg-white border text-blue-400 rounded-lg lg:flex p-4"
        >
          <svg
            class="w-16 h-16 fill-current lg:mr-4 ml-4 lg:ml-0"
            viewBox="0 0 20 20"
          >
            <path
              d="M17.431,2.156h-3.715c-0.228,0-0.413,0.186-0.413,0.413v6.973h-2.89V6.687c0-0.229-0.186-0.413-0.413-0.413H6.285c-0.228,0-0.413,0.184-0.413,0.413v6.388H2.569c-0.227,0-0.413,0.187-0.413,0.413v3.942c0,0.228,0.186,0.413,0.413,0.413h14.862c0.228,0,0.413-0.186,0.413-0.413V2.569C17.844,2.342,17.658,2.156,17.431,2.156 M5.872,17.019h-2.89v-3.117h2.89V17.019zM9.587,17.019h-2.89V7.1h2.89V17.019z M13.303,17.019h-2.89v-6.651h2.89V17.019z M17.019,17.019h-2.891V2.982h2.891V17.019z"
            ></path>
          </svg>

          <div class="text-gray-700">
            <p class="font-semibold text-3xl">0</p>
            <p class="font-bold text-sm">
              Nombre total de lecture avec relecture
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="lg:flex lg:flex-wrap mt-0">
      <div class="w-full xl:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">Recent Sales</p>

        <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
          <canvas id="buyers-chart" width="600" height="400"></canvas>
        </div>
      </div>

      <div class="w-full xl:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">Recent Reviews</p>

        <div class="w-full bg-white border rounded-lg p-4 mb-8 xl:mb-0">
          <canvas id="reviews-chart" width="600" height="400"></canvas>
        </div>
      </div>

      <div class="w-full xl:w-1/3 px-3">
        <p class="text-xl font-semibold mb-4">Recent Transactions</p>
        <div class="w-full bg-white border rounded-lg p-4">
          <div class="w-full bg-gray-100 border rounded-lg flex px-4 py-2 mb-4">
            <div>
              <p class="font-bold text-sm whitespace-nowrap">
                Nombre total de commentaires
              </p>
            </div>
            <span class="text-green-500 font-semibold text-lg ml-4">{{
              nbrCommentaire
            }}</span>
          </div>

          <div class="w-full bg-gray-100 border rounded-lg flex px-4 py-2 mb-4">
            <div>
              <p class="font-bold text-sm whitespace-nowrap">
                Nombre de lectures unique.
              </p>
            </div>
            <span class="text-green-500 font-semibold text-lg ml-6">{{
              nbrVue
            }}</span>
          </div>

          <div class="w-full bg-gray-100 border rounded-lg flex px-4 py-2">
            <p class="font-bold text-sm whitespace-nowrap mt-2">Dons reçu</p>

            <span class="text-green-500 font-semibold text-lg ml-12 mt-2"
              >{{ balace }} FCFA</span
            >
            <button
              @click="showModalRepas = true"
              class="bg-blue-600 text-white flex px-6 py-2 lg:ml-2 focus:outline-none poppins rounded-lg transform transition duration-300 hover:scale-105"
            >
            <span v-if="isLoading">
          <spiner />
        </span>
              
               <span v-else> Retrait</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <TheModal
    width="w-full md:w-2/3 lg:w-1/2"
    :is-open="showModalRepas"
    @close-modal="showModalRepas = false"
  >
    <template #header> Demande de Retrait</template>

    <template #body>
      <form action="#" method="POST" @submit.prevent="Retrait()">
        <div>
          <p class="mb-2 text-sm text-blue-500 text-center">
            Nous prélevons 15 % sur le montant de votre retrait.
          </p>
          <div class="mt-3 sm:mt-0 sm:col-span-2">
            <div class="px-4 py-5 bg-white p-6">
              <div class="grid grid-cols-8 gap-6">
                <!-- Select Payment Method -->
                <div class="col-span-8 sm:col-span-8">
                  <BaseLabel value="Moyen de retrait" />
                  <select
                    id="payment_method"
                    v-model="addform.payment_method"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  >
                    <option value="" disabled>
                      Sélectionnez un moyen de retrait
                    </option>
                    <option value="paypal">PayPal</option>
                    <option value="mobile_money">Mobile Money</option>
                    <option value="virement_bancaire">Virement bancaire</option>
                    <option value="western_union">Western Union</option>
                  </select>
                </div>

                <!-- PayPal Fields -->
                <div
                  v-if="addform.payment_method === 'paypal'"
                  class="col-span-8 sm:col-span-8"
                >
                  <BaseLabel value="Nom" />
                  <BaseInput id="name" v-model="addform.name" class="mt-2" />
                  <BaseLabel value="Email" class="mt-4" />
                  <BaseInput
                    id="email"
                    v-model="addform.email"
                    type="email"
                    class="mt-2"
                  />
                  <BaseLabel value="Montant" class="mt-4" />
                  <BaseInput
                    id="amount"
                    v-model="addform.amount"
                    type="number"
                    class="mt-2"
                  />
                </div>

                <!-- Mobile Money Fields -->
                <div
                  v-else-if="addform.payment_method === 'mobile_money'"
                  class="col-span-8 sm:col-span-8"
                >
                  <BaseLabel value="Nom" />
                  <BaseInput id="name" v-model="addform.name" class="mt-2" />
                  <BaseLabel value="Numéro (+229 xx-xx-xx-xx)" class="mt-4" />
                  <BaseInput
                    id="phone_number"
                    v-model="addform.phone_number"
                    type="tel"
                    class="mt-2"
                  />
                  <BaseLabel value="Montant" class="mt-4" />
                  <BaseInput
                    id="amount"
                    v-model="addform.amount"
                    type="number"
                    class="mt-2"
                  />
                </div>

                <!-- Virement Bancaire Fields -->
                <div
                  v-else-if="addform.payment_method === 'virement_bancaire'"
                  class="col-span-8 sm:col-span-8"
                >
                  <BaseLabel value="Nom" />
                  <BaseInput id="name" v-model="addform.name" class="mt-2" />
                  <BaseLabel value="Numéro RIB" class="mt-4" />
                  <BaseInput
                    id="rib_number"
                    v-model="addform.rib_number"
                    class="mt-2"
                  />
                  <BaseLabel value="Nom de la banque" class="mt-4" />
                  <BaseInput
                    id="bank_name"
                    v-model="addform.bank_name"
                    class="mt-2"
                  />
                  <BaseLabel value="Montant" class="mt-4" />
                  <BaseInput
                    id="amount"
                    v-model="addform.amount"
                    type="number"
                    class="mt-2"
                  />
                </div>

                <!-- Western Union Fields -->
                <div
                  v-else-if="addform.payment_method === 'western_union'"
                  class="col-span-8 sm:col-span-8"
                >
                  <BaseLabel value="Pays" />
                  <BaseInput
                    id="country"
                    v-model="addform.country"
                    class="mt-2"
                  />
                  <BaseLabel value="Nom" class="mt-4" />
                  <BaseInput id="name" v-model="addform.name" class="mt-2" />
                  <BaseLabel value="Montant" class="mt-4" />
                  <BaseInput
                    id="amount"
                    v-model="addform.amount"
                    type="number"
                    class="mt-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template #footer>
      <button
       @click="Retrait()"
        class="bg-blue-500 text-white mr-6 px-8 py-2 focus:outline-none poppins rounded-lg mt-2 mb-2 transform transition duration-300 hover:scale-105"
      >
        
        <span v-if="isLoading">
          <spiner />
        </span>
              
               <span v-else> Envoyer</span>
      </button>
    </template>
  </TheModal>
</template>

<script>
import TheModal from "../components/TheModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseInput from "../components/BaseInput.vue";
import spiner from "../components/spiner.vue";
import AlertComponent from "../components/AlertComponent.vue";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import axios from "axios";
export default {
  name: "RepasDash",
  components: {
    TheModal,
    spiner,
    BaseLabel,
    BaseInput,
      AlertComponent,
  },
  data() {
    return {
      addform: {
        payment_method: "",
        amount: "",
        user_id: "",
        numero_whatsapp: "",
        name: "",
        email: "",
        numeromomo: "",
        pays: "",
        numerorip: "",
        name_banque: "",
      },
      showAlert: false,
      alert: {
        type: "",
        message: "",
      },
       isLoading: false,
      processing: false,
      showDeleteRepasModal: false,
      showModalRepas: false,
      showModalRepasUpdate: false,
      repass: [],
      user: "",
      filteredRestaurants: [],
      categorys: [],
      profileID: "",
      abonner: [],
      books: [],
      nbrCommentaire: "",
      nbrMoyenne: "",
      nbrVue: "",
      balace: "",
    };
  },
  computed: {},
  created() {
    this.profile();
    this.book();
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.profileID = response.data.data.id;
          this.nbrCommentaire = response.data.data.nbr_comments;
          this.nbrVue = response.data.data.nbr_book_reads;
          this.balace = response.data.data.balance;
          this.subgt(this.profileID);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async subgt(userId) {
      try {
        const response = await axios.get(`/api/subscribers?autor_id=${userId}`);

        if (response.data) {
          this.abonner = response.data.data;
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async book() {
      try {
        const response = await axios.get("/api/books/user");
        if (response.data) {
          this.books = response.data.data;
          console.log(this.tags);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async Retrait() {
      try {
        this.isLoading = true;
        this.addform.user_id = this.profileID;
        const response = await axios.post("/api/request-amount", this.addform);
        if (response.status == 201 || response.status == 200) {
          this.showModalRepas = false;
          this.isLoading = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre demande a été reçue, veuillez vérifier votre boîte mail.",
            timeout: 5000,
          }).show();
        
          //this.profile();
          //this.role();
        }
      } catch (error) {
        // Gestion des erreurs HTTP
        this.isLoading = false;
        if (error.response ) {
          this.showAlert = true;
          this.alert.message = error.response.data.data;
        } else {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard.";
        }

        setTimeout(() => {
          this.showAlert = false;
        }, 5000);
      }
    },
  },
};
</script>
